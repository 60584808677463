<template>
  <div class="account-appeal">
    <el-container>
      <el-header class="main-header">
        <portal-page-header />
      </el-header>
      <el-main>
        <div class="main-content flex-column">
          <div class="appeal-text">账号申诉</div>
          <div class="appeal-tip"></div>
          <div class="confirm-text">若确认您的企业信息已被他人注册或注册手机号已失效，请点击下一步。</div>
          <div class="forget-text">
            如果忘记用户名或密码，您可以点击
            <span style="font-size: 18px;font-weight: 400;color: #5574df;margin:0 6px;cursor: pointer;" @click="toForgetPassword">忘记密码</span>
            找回。
          </div>
          <div class="next-step">
            <el-button style="background: #5574DF;" type="primary" @click="nextStep">下一步</el-button>
          </div>
          <div class="progress-text" @click="toAppealProgress">申诉进度查询</div>
        </div>
      </el-main>
    </el-container>
    <div class="bottom">
      <div class="bottom-text">Copyright © {{ domainNumber }} | {{ technicalSupport }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AccountAppeal',
  components: {},
  props: {},
  data() {
    return {
      domainNumber: this.$store.state.domainNumber,
      technicalSupport: this.$store.state.technicalSupport,
    }
  },
  computed: {},
  watch: {},
  created() { },
  mounted() { },
  methods: {
    // 点击忘记密码
    toForgetPassword() {
      this.$router.push({ name: 'ForgetPassword', params: {} })
    },
    //点击下一步按钮 进入申诉表单编辑页
    nextStep() {
      this.$router.push({ name: 'AppealEdit', params: {} })
    },
    //点击申诉进度查询
    toAppealProgress() {
      this.$router.push({ name: 'AppealProgress', params: {} })
    },
  }
}
</script>

<style scoped lang="less">
.flex-row {
  display: flex;
  align-items: center;
}

.flex-row-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-row-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-row-align-end {
  display: flex;
  align-items: flex-end;
}

.flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flex-column-justify-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.flex-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.account-appeal {
  height: 100%;
  .main-header {
    height: 85px !important;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0 2px 4px rgba(222, 227, 236, 0.8);
    z-index: 2;
  }

  .bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 16px;
    color: #999999;
    .bottom-text {
      position: fixed;
      bottom: 10px;
    }
  }
  .main-content {
    margin-top: 48px;
    .appeal-text {
      font-size: 24px;
      font-family: 'Harmony Medium';
      font-weight: 500;
      color: #3d3f44;
      line-height: 24px;
    }
    .appeal-tip {
      width: 108px;
      height: 108px;
      background-image: url('../../../assets/images/system/img_appeal_tip.png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      margin: 36px auto 30px;
    }
    .confirm-text {
      font-size: 22px;
      font-family: 'Harmony Medium';
      font-weight: 500;
      color: #3d3f44;
      line-height: 22px;
    }
    .forget-text {
      font-size: 18px;
      font-weight: 400;
      color: #3d3f44;
      line-height: 18px;
      margin: 30px auto 50px;
    }
    .next-step {
      /deep/.el-button {
        width: 264px !important;
      }
    }
    .progress-text {
      font-size: 18px;
      font-weight: 400;
      color: #5574df;
      margin-top: 32px;
      cursor: pointer;
    }
  }
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "account-appeal" },
    [
      _c(
        "el-container",
        [
          _c(
            "el-header",
            { staticClass: "main-header" },
            [_c("portal-page-header")],
            1
          ),
          _c("el-main", [
            _c("div", { staticClass: "main-content flex-column" }, [
              _c("div", { staticClass: "appeal-text" }, [_vm._v("账号申诉")]),
              _c("div", { staticClass: "appeal-tip" }),
              _c("div", { staticClass: "confirm-text" }, [
                _vm._v(
                  "若确认您的企业信息已被他人注册或注册手机号已失效，请点击下一步。"
                )
              ]),
              _c("div", { staticClass: "forget-text" }, [
                _vm._v(" 如果忘记用户名或密码，您可以点击 "),
                _c(
                  "span",
                  {
                    staticStyle: {
                      "font-size": "18px",
                      "font-weight": "400",
                      color: "#5574df",
                      margin: "0 6px",
                      cursor: "pointer"
                    },
                    on: { click: _vm.toForgetPassword }
                  },
                  [_vm._v("忘记密码")]
                ),
                _vm._v(" 找回。 ")
              ]),
              _c(
                "div",
                { staticClass: "next-step" },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { background: "#5574DF" },
                      attrs: { type: "primary" },
                      on: { click: _vm.nextStep }
                    },
                    [_vm._v("下一步")]
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "progress-text",
                  on: { click: _vm.toAppealProgress }
                },
                [_vm._v("申诉进度查询")]
              )
            ])
          ])
        ],
        1
      ),
      _c("div", { staticClass: "bottom" }, [
        _c("div", { staticClass: "bottom-text" }, [
          _vm._v(
            "Copyright © " +
              _vm._s(_vm.domainNumber) +
              " | " +
              _vm._s(_vm.technicalSupport)
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }